import React from "react"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import SEO from "../components/seo"
import {Row} from "react-bootstrap"

const LocationsPage = () => (
  <Layout className="main inner-page-with-banner">
    <SEO
      title={`Walpole Outdoors - Search`}
      description={`The standard all other fence and outdoor structures are measured by.`}
    />
    
    {/* CONTENT GOES HERE */}
    <InnerWrapper>
    <div className="page-hero"/>
    <Row className="portfolio-header">
          <h2 className="generalheader">Locations</h2>
          <p className="header-intro">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            represhnedrit in voluptate velit esse cillum doroe eu fugian nulla
            praiatur. Expetudr sint occaeat cufas.
          </p>
        </Row>
    </InnerWrapper>

  </Layout>
)

export default LocationsPage
